import React from 'react'
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const termsAndConditions =() =>{
    navigate(`/terms&Conditions`);
  }
  const privacyANdPolicy =() =>{
    navigate(`/PrivacyAndPolicy`);
  }
  const RefundPolicy =() =>{
    navigate(`/RefundPolicy`);
  }
  return (
    <footer id="footer" className="footer ">
    {/* Footer Top */}
    <div className="footer-top">
      <div className="container">
        <div className="row">
        <div className="col-lg-5 col-md-6 col-12">
            <div className="single-footer f-link">
              <h2>Quick Links</h2>
              <div className="row">
                <div className="col-lg-8 col-md-6 col-12">
                  <ul>
                    {/* <li style={{cursor:'pointer'}}>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Services
                      </a>
                    </li>
                    <li style={{cursor:'pointer'}}>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Testimonials
                      </a>
                    </li>
                    <li style={{cursor:'pointer'}}>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        FAQ on Bookings
                      </a>
                    </li>
                    <li style={{cursor:'pointer'}}>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Careers
                      </a>
                    </li> */}
                    <li  style={{cursor:'pointer'}}>
                      <a  onClick={termsAndConditions}>
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Terms and Conditions
                      </a>
                    </li>
                    <li  style={{cursor:'pointer'}}>
                      <a  onClick={privacyANdPolicy}>
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Privacy policy
                      </a>
                    </li>
                    <li  style={{cursor:'pointer'}}>
                      <a  onClick={RefundPolicy}>
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Refund policy
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-md-6 col-12">
                  <ul>
                    <li>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Finance
                      </a>
                    </li>
                    <li>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Testimonials
                      </a>
                    </li>
                    <li>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a  >
                        <i className="fa fa-caret-right" aria-hidden="true" />
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-footer">
              {/* <h2>Social Media</h2>
              <p>
                Follow us on social media and get latest updates.
              </p> */}
              {/* Social */}
              {/* <ul className="social">
                <li>
                  <a >
                    <i className="icofont-facebook" />
                  </a>
                </li>
                <li>
                  <a >
                    <i className="icofont-instagram" />
                  </a>
                </li>
                <li>
                  <a  >
                    <i className="icofont-twitter" />
                  </a>
                </li>
                <li>
                  <a  >
                    <i className="icofont-vimeo" />
                  </a>
                </li>
              </ul> */}
              {/* End Social */}
            </div>
          </div>
        
          {/* <div className="col-lg-3 col-md-6 col-12">
            <div className="single-footer">
              <h2>Newsletter</h2>
              <p>
                subscribe to our newsletter to get alerts news in your inbox.
              </p>
              <form
                action="mail/mail.php"
                method="get"
                target="_blank"
                className="newsletter-inner"
              >
                <input
                  name="email"
                  placeholder="Email Address"
                  className="common-input"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'Your email address'"
                  required=""
                  type="email"
                />
                <button className="button">
                  <i className="icofont icofont-paper-plane" />
                </button>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    {/*/ End Footer Top */}
    {/* Copyright */}
    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="copyright-content">
              <p>
                © Copyright 2025 | All Rights Reserved by{" "}
                <a>
                  SwasthaCare
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*/ End Copyright */}
  </footer>
  )
}

export default Footer