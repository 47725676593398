import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const PrivacyAndPolicy = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div className='container'>
            <div className="WordSection1" style={{paddingTop:'30px'}}>
                <p className="MsoTitle" align="center" style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "20.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Privacy policy{" "}
                    </span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "13.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care (
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        us”,{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        we”, or{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care”, which also includes its affiliates) is the author and
                        publisher of the internet resource www.swasthacare.com (
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Website”) on the world wide web as well as the software, services, and
                        applications provided by Swastha Care, including but not limited to the
                        mobile application{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span dir="LTR" />
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <span dir="LTR" />, and the software, services, and applications of the
                        brand names{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care Ray
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span dir="LTR" />
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <span dir="LTR" />,{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care Tab
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span dir="LTR" />
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <span dir="LTR" />,{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care Reach
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span dir="LTR" />
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <span dir="LTR" />,{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span
                        lang="IT"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        Hello
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span dir="LTR" />
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <span dir="LTR" />,{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Assured
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’{" "}
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        and{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Health Account
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span dir="LTR" />
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <span dir="LTR" />
                        (together with the Website, referred to as the{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Services”).
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care United Arab Emirates (
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span
                        lang="PT"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        UAE
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span dir="LTR" />
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <span dir="LTR" />) User
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s please refer to the Privacy Policy applicable in the UAE region here:
                        Swastha Care privacy policy only applicable for UAE
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        This privacy policy ("Privacy Policy") explains how we collect, use,
                        share, disclose, and protect Personal information about the Users of the
                        Services, including the Practitioners (as defined in the Terms of Use,
                        which may be accessed via the following weblink
                        https://swasthacare.com/privacy (the{" "}
                    </span>


                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Terms of Use”)), the End-Users (as defined in the Terms of Use), and the
                        visitors of Website (jointly and severally referred to as{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        you” or{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Users” in this Privacy Policy). We created this Privacy Policy to
                        demonstrate our commitment to the protection of your privacy and your
                        personal information. Your use of and access to the Services is subject to
                        this Privacy Policy and our Terms of Use. Any capitalized term used but
                        not defined in this Privacy Policy shall have the meaning attributed to it
                        in our Terms of Use.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL
                        BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND
                        POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE
                        PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING,
                        DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. WE
                        RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS
                        OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT
                        AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY OF THE SERVICES
                        OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE SERVICES ON BEHALF OF
                        SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR EMPLOYER),
                        YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO (I)
                        ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        S OR ENTITY
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR
                        COLLECTION, USE AND DISCLOSURE OF SUCH INDIVIDUAL
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        S OR ENTITY
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        S INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.
                    </span>
                </p>


                <span
                    lang="ES-TRAD"
                    style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                >
                    1. WHY THIS PRIVACY POLICY?
                </span>

                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        This Privacy Policy is provided in accordance with various legal
                        requirements, including:
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Section 43A of the Information Technology Act, 2000;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Regulation 4 of the Information Technology (Reasonable Security
                        Practices and Procedures and Sensitive Personal Information) Rules, 2011
                        (the "SPI Rules");
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Regulation 3(1) of the Information Technology (Intermediaries
                        Guidelines) Rules, 2011.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        This Privacy Policy outlines:
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - The types of information collected from Users, including Personal
                        Information (as defined in section 2 below) and Sensitive Personal Data or
                        Information (as defined in section 2 below);
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - The methods and purposes for collecting, using, processing, retaining,
                        and deleting such information;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - How and with whom Swastha Care may share this information.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        2. COLLECTION OF PERSONAL INFORMATION
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Some services require us to gather certain information about you to better
                        serve your needs. When you access our Services or communicate with us via
                        email, phone, or other means, we may ask for specific information that
                        personally identifies you or could be used to identify you. By using our
                        Services, you consent to the collection of such information by Swastha
                        Care. The information we may collect includes, but is not limited to:
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Contact details (e.g., email address, phone number);
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Demographic information (e.g., gender, date of birth, zip code);
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Usage data (e.g., service usage and appointment history);
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Insurance information (e.g., insurance provider and plan details);
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Additional information you voluntarily provide to us (e.g., through
                        emails or letters), including images and documents;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Data provided by Practitioners related to treatments you receive.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        This information may be categorized as either "personal information" or
                        "sensitive personal data or information" under the SPI Rules.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - "Personal Information" is any data that can directly or indirectly
                        identify a person, especially when combined with other available
                        information.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - "Sensitive Personal Data or Information" includes details about:
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span
                        lang="DE"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        {" "}
                        - Passwords;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        {" "}
                        - Financial information (e.g., bank account, credit/debit card details);
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        {" "}
                        - Health information (e.g., physical, mental, and medical records);
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span
                        lang="DE"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        {" "}
                        - Sexual orientation;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span
                        lang="DE"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        {" "}
                        - Biometric data;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span
                        lang="DE"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        {" "}
                        - Contractual information;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        {" "}
                        - Visitor details provided during registration or afterward;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span
                        lang="IT"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        {" "}
                        - Call data records.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care is allowed to use, collect, and disclose information that is
                        publicly available without needing your consent.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3. PRIVACY STATEMENTS
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1 APPLICABLE TO ALL USERS
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.1 Acceptance of Terms: By using or accessing the Services, Users agree
                        to the Terms of Use, which also include acceptance of this Privacy Policy.
                        Users who do not agree to any part of these terms may discontinue using
                        Swastha Care's Services.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.2 Information Collection: An indicative list of the information
                        Swastha Care may request from Users to enable their use of the Services is
                        available in the Schedule attached to this Privacy Policy.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.3 Voluntary Information: All information shared with Swastha Care by
                        Users, including Personal Information or Sensitive Personal Data, is
                        provided voluntarily. Swastha Care may use this information (a) for
                        Service provision, (b) for commercial purposes and aggregated statistical
                        analysis, (c) for research or data sharing in non-personally identifiable
                        form, (d) for communication related to bookings and feedback, (e) for
                        debugging customer support issues, and (f) to complete unfinished
                        transactions. Swastha Care may also use such data for:
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Identifying Users
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Publishing information on the website
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Marketing new products or services
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Collecting product/service feedback
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Analyzing usage patterns to improve products
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Processing payments through third-party providers
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.4 Consent for Sensitive Information: By agreeing to this Privacy
                        Policy, Users consent to the collection, use, and disclosure of their
                        Personal Information and Sensitive Personal Data as required by law.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.5 No Control Over Third-Party Content: Swastha Care does not control
                        or endorse content found in the Services and disclaims any liability for
                        actions resulting from using such content. Users waive any claims against
                        Swastha Care in relation to these actions.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.6 Accuracy of Information: Users are responsible for maintaining the
                        accuracy of the information they provide. If personal data changes, Users
                        should update it either through their account or by contacting Swastha
                        Care. Inaccurate or outdated information may lead Swastha Care to
                        discontinue providing services.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.7 Account Deletion: Users can request account deletion or cessation of
                        services by contacting support. Swastha Care will retain information only
                        as long as necessary to provide services and comply with legal
                        requirements. Once no longer needed, data may be anonymized for analytics.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.8 Opting Out of Communications: Users can opt-out of non-essential
                        communications (such as marketing) by emailing support.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.9 Payment Information: Swastha Care may require payment information
                        such as credit card details for paid services. Swastha Care uses secure
                        payment gateways to process such information, with the option for Users
                        not to save payment details. However, users should exercise caution when
                        using internet-based payment methods.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.10 Data Collection: Swastha Care automatically collects data like IP
                        addresses, browser types, and URLs of referring websites for analyzing
                        trends and improving services. This data is not shared with third parties
                        except in anonymized, aggregated form.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.11 Cookies: Swastha Care uses temporary cookies to improve website
                        functionality, manage user sessions, and assist with research. These
                        cookies do not store personal information and can be disabled in browser
                        settings, though some website features may not work as a result.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.12 Account Registration: Users can access limited features without
                        registering, but full access requires creating an account and providing
                        personal information like name, email, phone number, and financial
                        details.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.13 Third-Party Links: Swastha Care is not responsible for the content
                        or privacy practices of third-party websites linked from the Services.
                        Users should review third-party privacy policies before sharing personal
                        data on those sites.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.14 User-Generated Content: Users may post information accessible by
                        others, and Swastha Care is not responsible for any misuse of such
                        information. Swastha Care does not endorse or validate statements made by
                        other users.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.15 No External Data Collection: Swastha Care does not collect visitor
                        data from external sources, other than for registration purposes as
                        described in the Terms of Use.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.16 No Spam Policy: Swastha Care follows a strict "No-Spam" policy and
                        does not sell or share users
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’{" "}
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        email addresses with third parties without consent.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.17 Data Protection: Swastha Care has implemented security measures to
                        protect personal data from unauthorized access or misuse, but is not
                        liable for data loss resulting from unauthorized access via users'
                        devices.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.18 Security Measures: Swastha Care employs comprehensive security
                        policies and technical measures to safeguard personal data in line with
                        international best practices.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.1.19 Disclosure of Information: Swastha Care may disclose Personal
                        Information when required by law or to protect its rights or prevent harm.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span
                        lang="DE"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        3.2 APPLICABLE TO PRACTITIONERS
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.2.1 Collection of Practitioner Data: Swastha Care collects Personal
                        Information or Sensitive Personal Data from Practitioners during the
                        registration and application process.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.2.2 Practitioner Awareness: All Practitioners must read and understand
                        the privacy statements before submitting their information. If they
                        disagree, they must immediately discontinue use of Swastha Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s Services.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.2.3 Use of Practitioner Data: Swastha Care uses Practitioners' personal
                        data to identify them and may use it for commercial purposes, research,
                        analysis, and sharing aggregated data with third parties. Swastha Care may
                        also contact Practitioners for feedback and service updates.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.2.4 Displaying Practitioner Information: Practitioner information may be
                        listed on the website when a Practice is registered. Swastha Care does not
                        verify this data and encourages Practitioners to regularly check and
                        update their information.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.2.5 Information of Unregistered Practitioners: Swastha Care may display
                        information of unregistered Practitioners who have consented to data
                        collection. Swastha Care takes steps to ensure accuracy but is not liable
                        for incorrect data from non-registered sources.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3 END-USERS NOTE
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        This section applies to all End-Users.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.1 During the registration and application process on the Website,
                        certain information, including Personal Information or Sensitive Personal
                        Data, is collected from End-Users.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.2 All statements in this Privacy Policy apply to End-Users. It is
                        necessary for End-Users to read and understand these privacy terms before
                        submitting any Personal Information or Sensitive Personal Data to Swastha
                        Care. If they do not agree, they must exit Swastha Care immediately.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.3 If you have accidentally submitted information to Swastha Care
                        before reading this Privacy Policy, and you disagree with how your
                        information is collected, processed, stored, used, or disclosed, you can
                        modify or delete that information through the Website. Additionally, you
                        may inquire by emailing privacy@swasthacare.com to check whether Swastha
                        Care holds your personal data and request its deletion.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.4 Any personally identifiable information provided by End-Users on the
                        Website is used to help identify and describe them. Swastha Care may also
                        collect non-personally identifiable data, such as usage patterns, which is
                        exclusively owned by Swastha Care. This aggregated information may be used
                        for research, statistical analysis, or business intelligence, and may be
                        shared with third parties or affiliates in non-personally identifiable
                        form. Swastha Care may use anonymized End-User data for purposes
                        including:
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Analyzing usage patterns to improve product design.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Conducting research and development for new technologies.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Incorporating the analysis into other commercial products.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        - Sharing the results with third parties for commercial use.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.5 Swastha Care will communicate with End-Users via email, phone, and
                        notices on the Website or other service platforms. End-Users can update
                        their contact preferences anytime through their account settings at
                        www.swasthacare.com.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.6 Swastha Care may conduct optional user surveys to collect
                        preferences from End-Users. Survey responses are kept anonymous. Swastha
                        Care may also run contests asking for contact and demographic information,
                        which helps improve its Services.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.7 End-Users who avail Services from Practitioners or clinics with the{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Assured” badge consent to sharing treatment-related data, including
                        medical records, with Swastha Care to effectively deliver Services.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.8 Swastha Care may keep records of communications, including phone
                        calls and electronic messages related to appointments or other Services,
                        for administration, customer support, research, development, and improving
                        Practitioner listings.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.9 All Swastha Care employees and data processors with access to
                        sensitive personal data are required to maintain confidentiality. Swastha
                        Care has implemented industry-standard security measures to protect
                        personal data, which third-party data processors must also comply with.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.10 Swastha Care may transfer End-User information to a third party
                        during reorganization or asset sale. Any third-party receiving data will
                        continue using it in accordance with the Terms of Use.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.3.11 For providing Services, Swastha Care may share End-Users' Personal
                        Information with third-party contractors assisting in service delivery or
                        maintaining the Website, or third-party payment processors. These
                        contractors can only use the data for processing payments and related
                        tasks, and may not use it for other purposes. End-Users consent to such
                        data sharing solely for the intended purposes.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.4 CASUAL VISITORS NOTE
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.4.1 Swastha Care does not automatically collect sensitive personal data
                        from casual visitors who are merely browsing the Website.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.4.2 However, casual visitors are still required to read and understand
                        the privacy statements set forth in this policy. If they do not agree,
                        they must leave the Website immediately.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.4.3 If a casual visitor inadvertently browses other pages before reading
                        the Privacy Policy and disagrees with how their information is collected
                        or used, they can clear temporary cookies by quitting the browser
                        application. It
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s recommended to use the{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        clear cookies” function of the browser, as Swastha Care cannot predict or
                        guarantee the behavior of all visitors
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’{" "}
                    </span>
                    <span
                        lang="IT"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        devices.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        3.4.4 If a casual visitor submits any personal data to Swastha Care,
                        including via email, post, or registration on the Website, they will no
                        longer be considered a casual visitor and will be treated as a User. In
                        that case, all Privacy Policy statements apply.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            4. CONFIDENTIALITY AND SECURITY
                        </span>
                    </b>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "1.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Times Roman",serif' }}>
                        •
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        4.1 Swastha Care stores your Personal Information electronically on its
                        own systems and those of its employees, which may occasionally be
                        converted to physical form. Swastha Care takes all necessary precautions
                        to protect your personal data both online and offline, implementing
                        security measures that include managerial, technical, operational, and
                        physical safeguards appropriate to the type of information collected and
                        Swastha Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s business operations.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "1.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Times Roman",serif' }}>
                        •
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        4.2 No administrator at Swastha Care will know your password. It is
                        essential for you to protect your password and devices from unauthorized
                        access. Always log off when you're done using the Website. Swastha Care is
                        not responsible for any unauthorized use of your account or password. If
                        you suspect unauthorized access to your account, immediately notify
                        Swastha Care by emailing support@swasthacare.com. You will be liable for
                        any loss Swastha Care incurs due to unauthorized use of your account and
                        password.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "1.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Times Roman",serif' }}>
                        •
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        4.3 Swastha Care ensures that user information is accessible only to
                        employees, agents, partners, or third parties on a need-to-know basis and
                        binds its employees to strict confidentiality obligations.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "1.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Times Roman",serif' }}>
                        •
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        4.4 As part of its functionality, Swastha Care helps doctors manage and
                        organize patient information. Consequently, Swastha Care may retain and
                        provide such records to relevant authorities or to doctors who request
                        access to them.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "1.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Times Roman",serif' }}>
                        •
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        4.5 Similarly, Swastha Care assists patients in accessing their own
                        information. Therefore, Swastha Care may retain and provide these records
                        to patients or their doctors as needed.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "1.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Times Roman",serif' }}>
                        •
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        4.6 Despite these efforts, Swastha Care is not responsible for the
                        confidentiality, security, or distribution of your Personal Information by
                        our partners or third parties outside the scope of our agreement with
                        them. Furthermore, Swastha Care is not liable for any security breaches or
                        actions by third parties or events beyond its reasonable control, such as
                        government actions, hacking, unauthorized access to data, system crashes,
                        breaches of security or encryption, or poor-quality internet or telephone
                        services.
                    </span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontFamily: '"Georgia",serif', color: "gray" }}>&nbsp;</span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <b>
                        <span lang="ES-TRAD" style={{ fontFamily: '"Georgia",serif' }}>
                            5. CHANGES TO PRIVACY POLICY
                        </span>
                    </b>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care may update this Privacy Policy at any time, with or without
                        notice. If there are significant changes in how Swastha Care handles your
                        personal information or in the policy itself, Swastha Care will either
                        display a notice on the Website or send you an email to inform you. You
                        will be able to review the changes before continuing to use the Services.
                        If you disagree with any changes, and wish to stop using the Services, you
                        may contact support@swasthacare.com to deactivate your account. Unless
                        stated otherwise, the current Privacy Policy will apply to all information
                        Swastha Care holds about you. If you continue using the Website or
                        Services after changes have been communicated, you consent to the updated
                        terms.
                    </span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontFamily: '"Georgia",serif', color: "gray" }}>&nbsp;</span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            6. CHILDREN'S AND MINOR'S PRIVACY
                        </span>
                    </b>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care strongly encourages parents and guardians to supervise their
                        minor children's online activities and to use parental control tools
                        available from online services and software providers to ensure a safe
                        online environment. These tools can prevent minors from sharing personal
                        information online without parental consent. Although Swastha Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s Website and Services are not intended for minors, Swastha Care respects
                        the privacy of children who may use the internet or mobile applications
                        inadvertently.
                    </span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontFamily: '"Georgia",serif', color: "gray" }}>&nbsp;</span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            7. CONSENT TO THIS POLICY
                        </span>
                    </b>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        By using the Website and its Services, you acknowledge that this Privacy
                        Policy is part of the Terms of Use. You unconditionally agree to the terms
                        of this Privacy Policy and consent to Swastha Care collecting, processing,
                        and disclosing your Personal Information as outlined herein. Your use of
                        the Website and Services is subject to both the Privacy Policy and the
                        Terms of Use.
                    </span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontFamily: '"Georgia",serif', color: "gray" }}>&nbsp;</span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <b>
                        <span lang="ES-TRAD" style={{ fontFamily: '"Georgia",serif' }}>
                            8. ADDRESS FOR PRIVACY QUESTIONS
                        </span>
                    </b>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        If you have any questions about this Privacy Policy or Swastha Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s data collection, use, and disclosure practices, you may contact Swastha
                        Care's Data Protection Officer as per the provisions of PDPA. Swastha Care
                        will make reasonable efforts to respond to your queries or concerns
                        regarding your personal data. If you have any grievances regarding our use
                        of your information, you may communicate them to the Data Protection
                        Officer:
                    </span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span
                        lang="DE"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        Name: Surabhi Patodia
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span
                        lang="NL"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        Swastha Care Pvt Ltd
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span
                        lang="ES-TRAD"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        WeWork Salarpuria Symbiosis,
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Arekere Village, Begur Hobli,
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Bannerghatta Road,
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span
                        lang="IT"
                        style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}
                    >
                        Bangalore - 560076
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Email:{" "}
                    </span>
                    <span className="Hyperlink0">
                        <a href="mailto:privacy@swasthacare.com">
                            <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                                privacy@swasthacare.com
                            </span>
                        </a>
                    </span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>&nbsp;</span>
                    </b>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>&nbsp;</span>
                    </b>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>&nbsp;</span>
                    </b>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>&nbsp;</span>
                    </b>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span lang="DE" style={{ fontFamily: '"Georgia",serif' }}>
                            SCHEDULE:
                        </span>
                    </b>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            <br />
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            Indicative List of Information by Service Type
                        </span>
                    </b>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Times Roman",serif' }}>
                            1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            End-Users Registering for an Account on the Website or Swastha Care
                            Mobile App:
                        </span>
                    </b>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        When you create an account, you will be required to provide details such
                        as your [name, mobile number, email address], and any other information
                        requested during registration. This allows us to offer services like
                        booking appointments and storing health-related data.
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        For every Practitioner listed on Swastha Care, you may see a{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        show number
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        option. By selecting this, you will make a call via a free telephony
                        service provided by Swastha Care, and the call will be recorded and stored
                        on Swastha Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s servers. An IVR message will inform you about the call recording and ask
                        for your consent. If you do not consent to call recording, Swastha Care
                        may refuse to provide the related services. All call records will be
                        handled according to this Privacy Policy.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Times Roman",serif' }}>
                            2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            End-Users Using the Website Without Registering (Guest Users):
                        </span>
                    </b>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        You can use the Website without creating an account, but to book an
                        appointment, you may be asked to provide certain information (like your
                        [mobile number]) to confirm the appointment.
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        For every Practitioner listed, the 'show number' option will allow you to
                        make calls through Swastha Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s free telephony service. Calls will be recorded and stored in Swastha
                        Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s servers, with an IVR message stating the call recording and requesting
                        your consent. If you decline the recording, Swastha Care reserves the
                        right to refuse the services. All records will be managed per this Privacy
                        Policy.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Times Roman",serif' }}>
                            3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            Practitioners Using the Free Listing Service on the Website or Swastha
                            Care Mobile App (with Account Registration):
                        </span>
                    </b>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        As a Practitioner, you may need to provide information like your [name,
                        mobile number, email address] and other details during the registration
                        process to create an account. Swastha Care may send email or SMS
                        confirmations or other communications to End-Users related to bookings,
                        appointments, or other interactions facilitated by Swastha Care.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Times Roman",serif' }}>
                            4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            Practitioners Using the Free Listing Service on the Website or Swastha
                            Care Mobile App (without Account Registration):
                        </span>
                    </b>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        As a Practitioner, you can be listed without registering for an account by
                        providing your [name, mobile number, email address], and other information
                        as requested by Swastha Care's agents or employees. This information will
                        be kept until you decide to register for an account, at which point
                        Swastha Care may contact you. After collecting your details, Swastha Care
                        will send a confirmation email regarding the listing on the Website. If
                        you do not wish to be listed, please notify Swastha Care immediately at
                        support@swasthacare.com.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Times Roman",serif' }}>
                            5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            Practitioners Using the{" "}
                        </span>
                    </b>
                    <span dir="RTL" />
                    <b>
                        <span lang="AR-SA" dir="RTL">
                            <span dir="RTL" />‘
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>Ray</span>
                    </b>
                    <span dir="RTL" />
                    <b>
                        <span lang="AR-SA" dir="RTL">
                            <span dir="RTL" />’{" "}
                        </span>
                    </b>
                    <b>
                        <span lang="DE" style={{ fontFamily: '"Georgia",serif' }}>
                            and/or{" "}
                        </span>
                    </b>
                    <span dir="RTL" />
                    <b>
                        <span lang="AR-SA" dir="RTL">
                            <span dir="RTL" />‘
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>Tab</span>
                    </b>
                    <span dir="RTL" />
                    <b>
                        <span lang="AR-SA" dir="RTL">
                            <span dir="RTL" />’{" "}
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>Products:</span>
                    </b>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        You will need to create an account and provide information such as your
                        [name, mobile number, email address, digital signature], and any other
                        details requested by Swastha Care during registration. Swastha Care uses
                        the digital signature for prescriptions and clinical notes in accordance
                        with regulatory requirements. Swastha Care will not use your digital
                        signature for any other purpose. After registration, Swastha Care may
                        access non-personally identifiable patient data. You must inform your
                        patients about this access.
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Swastha Care has the discretion to extend or withdraw the 'ABS' (Instant)
                        functionality based on your End-User appointment volume. Notifications of
                        these changes will be sent to you.
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        You can opt to activate{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />‘
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        End-User Feedback
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’{" "}
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        for your patients, which allows their contact details to be shared with
                        Swastha Care
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />’
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        s feedback system. Patients can choose to provide feedback anonymously.
                        You must inform patients about the possibility of receiving feedback
                        requests.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Times Roman",serif' }}>
                            6.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            Practitioners Using the{" "}
                        </span>
                    </b>
                    <span dir="RTL" />
                    <b>
                        <span lang="AR-SA" dir="RTL">
                            <span dir="RTL" />‘
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>Swastha Care Reach</span>
                    </b>
                    <span dir="RTL" />
                    <b>
                        <span lang="AR-SA" dir="RTL">
                            <span dir="RTL" />’{" "}
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>Product:</span>
                    </b>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        You will be required to create an account and provide details such as your
                        [name, mobile number, email address], and other information as requested
                        by Swastha Care during the registration process.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Times Roman",serif' }}>
                            7.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            End-Users and Practitioners Using the Consult Platform:
                        </span>
                    </b>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        You may need to create an account and provide information like your name,
                        mobile number, email address, and other requested details to complete
                        registration on the Swastha Care Consult platform.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-25.0pt",
                        lineHeight: "normal"
                    }}
                >
                    <b>
                        <span style={{ fontFamily: '"Times Roman",serif' }}>
                            8.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>
                            End-Users Receiving Services from Practitioners with the{" "}
                        </span>
                    </b>
                    <span dir="RTL" />
                    <b>
                        <span lang="AR-SA" dir="RTL">
                            <span dir="RTL" />“
                        </span>
                    </b>
                    <b>
                        <span style={{ fontFamily: '"Georgia",serif' }}>Assured” Badge:</span>
                    </b>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        <br />
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        By using the services of a Practitioner with the{" "}
                    </span>
                    <span dir="RTL" />
                    <span lang="AR-SA" dir="RTL" style={{ fontSize: "11.0pt" }}>
                        <span dir="RTL" />“
                    </span>
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        Assured” badge, you consent to share treatment-related data, including
                        medical records, with Swastha Care for the sole purpose of providing
                        services effectively to End-Users.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontSize: "11.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    align="center"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        textAlign: "center",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontFamily: '"Georgia",serif' }}>Copyright </span>
                    <span lang="DE" style={{ fontFamily: '"Georgia",serif' }}>
                        ©{" "}
                    </span>
                    <span style={{ fontFamily: '"Georgia",serif' }}>
                        2017, Swastha Care. All rights reserved.
                    </span>
                </p>
                <p
                    className="Default"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        lineHeight: "normal"
                    }}
                >
                    <span style={{ fontFamily: '"Georgia",serif' }}>&nbsp;</span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "13.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "13.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p
                    className="Default"
                    style={{ marginTop: "0in", textAlign: "justify", lineHeight: "normal" }}
                >
                    <span style={{ fontSize: "13.0pt", fontFamily: '"Georgia",serif' }}>
                        &nbsp;
                    </span>
                </p>
                <p className="Default" style={{ marginTop: "0in", lineHeight: "normal" }}>
                    &nbsp;
                </p>
            </div>

        </div>
    )
}

export default PrivacyAndPolicy