import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [activeTab, setActiveTab] = useState('');
    const navigate = useNavigate();

    const handleNavigation = (route, tabName) => {
        navigate(`/${route}`);
        setActiveTab(tabName);
    };

    const handleScrollToSection = (sectionId, tabName) => {
        navigate(`/#${sectionId}`); 
        setActiveTab(tabName);
        setTimeout(() => {
            const targetSection = document.getElementById(sectionId);
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <header className="header">
            <div className="header-inner">
                <div className="container">
                    <div className="inner">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-12">
                                <div onClick={() => handleScrollToSection('home', 'home')} className="logo" role="button" tabIndex={0}>
                                    <img width={'100%'} src="img/logo.png" alt="Logo" />
                                </div>
                                <div className="mobile-nav" />
                            </div>
                            <div className="col-lg-7 col-md-9 col-12">
                                <div className="main-menu">
                                    <nav className="navigation">
                                        <ul className="nav menu">
                                            <li 
                                                onClick={() => handleScrollToSection('home', 'home')} 
                                                className={activeTab === 'home' ? 'active' : ''}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                <a>Home</a>
                                            </li>
                                            <li 
                                                onClick={() => handleScrollToSection('services', 'services')} 
                                                className={activeTab === 'services' ? 'active' : ''}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                <a>Services</a>
                                            </li>
                                            <li 
                                                onClick={() => handleScrollToSection('blogs', 'blogs')} 
                                                className={activeTab === 'blogs' ? 'active' : ''}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                <a>Blogs</a>
                                            </li>
                                            <li 
                                                onClick={() => handleScrollToSection('contact', 'contactus')} 
                                                className={activeTab === 'contactus' ? 'active' : ''}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                <a>Contact Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                <div className="get-quote">
                                    <a className="btn">Download App</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;