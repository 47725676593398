import logo from './logo.svg';
import './App.css';
import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Landing from './screens/Landing';
import Layout from './screens/Layout';
import Services from './screens/Services';
import Blogs from './screens/Blogs';
import Contactus from './screens/Contactus';
import TermsAndConditions from './screens/termsAndConditions';
import PrivacyAndPolicy from './screens/PrivacyAndPolicy';
import Refund from './screens/Refund';
function App() {


  return (
    <>
   
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/terms&Conditions" element={<TermsAndConditions />} />
          <Route path="/PrivacyAndPolicy" element={<PrivacyAndPolicy />} />
          <Route path="/RefundPolicy" element={<Refund />} />
        </Route>
      </Routes>

    </>
  );
}

export default App;
