import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const Refund = () => {
       const { pathname } = useLocation();
    
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);
    return (
        <div>
          
            <div className='container' style={{lineHeight:'60px',padding:'20px'}}>
            <h4 style={{textAlign:'center'}}> **Refund Policy**</h4>
                At **SwasthaCare**, we strive to ensure our customers are satisfied with our services. If you are not entirely satisfied with your purchase, we are here to help.

                <p style={{fontWeight:'bold'}}>Refund Eligibility</p>
                To be eligible for a refund:
                - Refund requests for **doctor appointments** or **lab tests** must be made at least **24 hours** before the scheduled appointment or test.
                - Refunds are not applicable for completed consultations or tests.
                - Proof of purchase (such as an invoice or receipt) must be provided.

                <p style={{fontWeight:'bold'}}>Refund Process</p>
                Once your refund request is received and approved, we will initiate the refund process. Refunds will be processed as follows:
                - Refunds will be credited to the original payment method used during the purchase.
                - It may take **7-10 business days** for the refunded amount to reflect in your account, depending on your bank or payment provider's policies.

                <p style={{fontWeight:'bold'}}>Non-Refundable Items</p>
                Certain items and services may not be eligible for a refund:
                - Completed doctor consultations or lab tests.
                - Digital prescriptions or medical records that have already been shared.
                - Bookings made under special discounts or promotional offers (unless faulty).

                <p style={{fontWeight:'bold'}}>Cancellation Policy</p>
                - Doctor appointments and lab tests can be canceled within **24 hours** before the scheduled time. Cancellations made after this period may not be eligible for a refund.



                <p style={{fontWeight:'bold'}}>Contact Us</p>
            If you have any questions about our refund policy or need to request a refund, please contact us at:

            **Email: info@swasthacare.in
            **Phone: +91 8688344719
            **Address:Tirupati
            </div>

          


        </div>
    )
}

export default Refund